<template>
  <div id="app">
    <LoginView ref="loginView" />
  </div>
</template>

<script>
import LoginView from './components/LoginView.vue'

export default {
  name: 'App',
  components: {
    LoginView
  },
  data(){
    return {
    }
  },
  methods: {
  },
  created() {
  },
  async mounted() {
  },
}
</script>

<style>
html,
body {
  width: 100%;
  height: 100%;
  font-family: "Microsoft YaHei" !important;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 1px;
}
</style>
