export function GetQueryStringArgs()
{
	let qs = (location.search.length > 0 ? location.search.substring(1) : "");
	let args = {};
	let items = qs.length ? qs.split("&") : [];
	let len = items.length;
	for (let i=0; i < len; i++)
	{
		let n = items[i].indexOf('=');
		let name = items[i].substring(0, n);
		let value = items[i].substring(n+1);
		if (name)args[name] = value;
	}
	return args;
}