import Vue from 'vue'
import App from './App.vue'

import { GetQueryStringArgs } from '@/util.js'
import md5 from "md5";
import {Base64} from 'js-base64';

Vue.config.productionTip = false

window.yundesign_index_window = true;
window.yundesign_index_window_loginmessage = undefined;
let o = GetQueryStringArgs();
if (o.loginmessage){
  let s = Base64.decode(o.loginmessage);
  window.yundesign_index_window_loginmessage = JSON.parse(s);
}
window.CloseWindow = function(){
  window.close();
}
window.sendMessageToParent = function (o) {
  try {
    if (window.parent?.location?.pathname == '/MyDesignTool/') {
      let win = window.parent;
      let data = {
        func: 'Message_YunDesignFeedback',
        data: o
      }
      win?.postMessage(data, "*");
      return true;
    }
  } catch (e) {
    console.log(e);
  }
}
window.GetDefaultYunPage = function () {
  return '/htmloutput/QdHome/index.html';
}
window.ResetYunDesignLogin = function (qdurl) {
  let s = localStorage.getItem('yundesign_login');
  let o = s?.[0] == '{' ? JSON.parse(s) : {};
  let url = location.href;
  let k = md5(url);
  o[k] = {
    url: url,
    time: 0,
    qdhome: qdurl,
  };
  localStorage.setItem('yundesign_login', JSON.stringify(o));
}
window.UpdateYunDesignLogin = function () {
  let update_yundesign_login = function () {
    let s = localStorage.getItem('yundesign_login');
    let o = s?.[0] == '{' ? JSON.parse(s) : {};
    let url = location.href;
    let k = md5(url);
    if (o[k]) {
      o[k].time = Date.now();
      localStorage.setItem('yundesign_login', JSON.stringify(o));
    }
  }
  update_yundesign_login();
  setInterval(() => update_yundesign_login(), 20000);
}

new Vue({
  render: h => h(App),
}).$mount('#app')
