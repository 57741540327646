<template>
  <div class="loginview">
    <iframe ref="myview" :src="url" style="width:100%; height:100%;border: none;"></iframe>
  </div>
</template>

<script>
import { GetQueryStringArgs } from '@/util.js'

export default {
  name: 'LoginView',
  data() {
    return {
      url: '',
    }
  },
  methods: {
  },
  mounted() {
    let o = GetQueryStringArgs();
    this.url = '/login/login.html' + window.location.search;
    if (o?.lt === 'npwd') this.url = '/login/npwd.html' + window.location.search;
    if (o?.lt === 'entry') this.url = '/login/entry.html' + window.location.search;
    if (o?.lt === 'kjl') this.url = '/login/login_kjl.html' + window.location.search;
  },
}
</script>

<style scoped>
.loginview {
  width: 100%;
  height: 100%;
}
</style>
